@import '../../../../../style/typography.scss';
@import '../../../../../style/app.scss';

.setup-ipa {
    padding: 8px;
    display: grid;
    grid-template-columns: minmax(min-content, 292px) 1fr;
    column-gap: 16px;
    align-items: flex-start;
    width: 100%;

    h3 {
        @extend .font-size-200-semibold;
    }

    h4 {
        @extend .font-size-75-bold;
        text-transform: uppercase;
    }

    p:not(:global(.callout-description)) {
        @extend .font-size-75-regular;
        text-align: start;
        word-break: keep-all;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__header {
        &__subtitle {
            @extend .font-size-100-regular;
            margin-block-end: 16px;
        }

        &__toggle-container {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        & > div {
            @extend .card;
        }

        &__footer {
            display: flex;
            gap: 12px;
            justify-content: flex-end;
            margin-block-end: 80px;
        }
    }

    &__content,
    &__location {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }
}
