@import '../../../../../style/typography.scss';

.gerenciador-header {
    display: flex;
    flex-direction: column;

    :global(.rs-dropdown) {
        background: var(--gray-300);
        border-radius: 6px;
    }

    :global(.rs-btn.rs-btn-subtle.rs-dropdown-toggle) {
        color: var(--gray-800);
        font-size: 12px;
        line-height: 18px;
        padding-bottom: 4px;
        padding-bottom: 4px;
    }

    :global(.rs-dropdown-toggle-caret) {
        padding: -5px;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    &__title-integration {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    &__button {
        white-space: nowrap;
    }

    &__icon {
        margin-right: 6px;
        margin-bottom: -2px;
        color: var(--gray-600);
    }

    &__subtitle {
        margin-top: -10px;
        margin-bottom: 10px;
    }
}
