.gerenciador-page {
    --_left-spacing_correction: -4px;

    :global(.rs-table-cell-group.rs-table-cell-group-scroll) {
        position: relative;
        left: var(--_left-spacing_correction);
    }

    :global([role='columnheader'] .rs-table-cell-content:has(.rs-checkbox)),
    :global(.rs-checkbox) {
        justify-content: center;
    }

    :global(.rs-table-body-row-wrapper) [role='row'] {
        min-width: 100% !important;
        border: 1px solid transparent;
        &:has([data-table-row-is-expanded]) {
            border-color: #bcbcbc;
        }
    }

    &__big-numbers {
        margin: 0px !important;
        padding: 12px !important;
    }

    &__table {
        --_table-border: 1px solid var(--gray-300, #eaeaea);
        border-radius: 6px;
        border: var(--_table-border);

        &__row {
            border-bottom: var(--_table-border);
        }

        &__header-cell {
            p {
                display: block;
                margin: 0px;
                line-height: 1;
            }
        }

        [role='row']:nth-child(odd) {
            border-bottom: 1px solid var(--gray-300, #eaeaea);
            background: #f9f9f9;
        }

        [role='gridcell'] {
            background: transparent;
        }
    }

    &__margem-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}

.table__cell {
    &__margem-objetiva {
        display: flex;
        align-items: center;
        gap: 2px;
        svg {
            color: var(--gray-600);
        }
    }
}
