.modal {
    --_img-text-height: 84px;
    --_icon-check-size: 24px;
    --_gap: 1.5rem;

    width: min(800px, 90dvw);
    max-height: 850px;
    text-wrap: balance;

    img {
        display: block;
        max-width: 100%;
    }

    [role='document'] {
        padding: 2rem;
        background: #6e3aaf;
    }

    :global(.rs-modal-body) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--_gap);
        margin: 0px;
        padding: 0px;
        color: var(--white);
    }

    .logo {
        max-width: 6.5rem;
    }

    .title {
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2;
        strong {
            font-weight: 800;
        }
    }

    .img__container {
        position: relative;
        z-index: 100;
        margin-block-end: calc(var(--_img-text-height) / 2);

        span {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 10;
            transform: translateY(50%);

            display: grid;
            place-content: center;

            height: var(--_img-text-height);
            margin-inline: 10%;
            padding-inline: 2rem;
            background: #9256d9;
            border-radius: 0.5rem;

            font-weight: 500;
            line-height: 26px;
            font-size: 20px;
            text-align: center;
        }
    }

    ul,
    li {
        margin: 0px;
        padding: 0px;
        list-style: none;
    }

    ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        align-items: center;
        gap: 1rem;
        width: 100%;
    }

    li {
        display: grid;
        grid-template-columns: min-content 1fr;
        align-items: start;
        gap: 0.5rem;
        line-height: 18px;
        font-weight: 400;
        font-size: 14px;

        &::before {
            content: '';
            display: inline-block;
            background: url('../../assets/icons/icon-check.svg') no-repeat;
            width: var(--_icon-check-size);
            height: var(--_icon-check-size);
            margin-block-start: 2px;
        }
    }

    button {
        margin: 0px;
        outline: none;
        background: #e8aa23;
        padding: 0.75rem 2rem;
        color: var(--white);
        text-decoration: none;
        border-radius: 0.5rem;
        font-weight: 600;
    }
}
