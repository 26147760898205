@import '../../../../../style/typography.scss';
@import '../../../../../style/app.scss';

.setup-ipa__main-config {
    padding: 16px;
    background-color: #fff;
    border-radius: 6px;
    margin: 0;

    h3 {
        @extend .font-size-200-semibold;
    }

    &__header {
        &__subtitle {
            @extend .font-size-100-regular;
        }
    }
}

.setup-ipa__price-preference-competitor {
    border-radius: 6px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: #fff;

    &__content {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
            margin-top: 0;
        }

        h4 {
            @extend .font-size-100-semibold;
            text-transform: none;
        }

        p {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.setup-ipa__main-config__footer {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
}

.footer {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}

.wrapper-dias-concorrente {
    display: flex;
    align-items: center;
    gap: 16px;

    .input-box {
        width: 96px;
    }

    > span {
        @extend .font-size-75-regular;
        --text-color: #959595;
        padding-block: 8px;
    }
}
