.filter-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px;
    border: 1px solid #eaeaea;

    .row {
        &,
        & > div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
        }
        justify-content: space-between;
    }

    button {
        border-radius: 9999px !important;
        height: 36px;
        padding-inline: 12px;
        display: grid;
        place-content: center;
    }
}

.filter-section__button {
    border-radius: 9999px;
    height: 36px;
    padding-inline: 12px;
    display: grid;
    place-content: center;
}
