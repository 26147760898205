.panel-plan-tags {
    margin-bottom: 44px;
    padding-left: 5px;
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
    text-transform: uppercase;


    .title {
        margin-right: 12px;
    }

    .itens {
        display: inline-block;

        .tags {
            background-color: #378EF0;
            border-radius: 15px;
            color: #FFFFFF;
            padding: 4px 16px;
            margin-right: 12px;
        }
    }
}
