.fixed-banner-component {
    padding: 0 20px;

    .banner-area {
        // background: #C6C6C6;
        cursor: pointer;
        display: table;
        height: 90px;
        padding: 20px 0;
        text-align: center;
        width: 100%;

        img {
            width: 100%;
            border-radius: 6px;

        }
    }

}