.date-range-picker-pill-container {
    position: relative;
    width: fit-content;

    &__arrow-down-icon {
        position: absolute;
        top: 11px;
        right: 12px;
        z-index: 9;
        cursor: pointer;
    }

    .left-icon {
        position: absolute;
        top: 7px;
        left: 12px;
        z-index: 9;
    }

    #date-range-picker:has(.left-icon) {
        padding-left: 50px !important;
    }

    #date-range-picker {

        .rs-picker-toggle-caret {
            display: none !important;
        }

        .rs-picker-toggle-clean {
            right: 12px !important;
        }
    }

}

.date-range-has-value{
    .date-range-picker-pill-container__arrow-down-icon {
        right: 30px;
    }
}

.date-range-picker-pill-container:has(.left-icon) {
    #date-range-picker {
        padding-left: 32px !important;
    }
}
