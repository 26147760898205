.block-page {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(55, 142, 240, 0.2);
    backdrop-filter: blur(6px);
    top: 0;
    z-index: 5;
}

.block-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 300px;
}

.title, .subtitle {
    color: #323232;
    margin: 0;
}

.title {
    font-weight: 600;
    font-size: 20px;
}

.subtitle {
    font-size: 14px;
}

.lock-icon {
    color: #0D66D0;
    width: 54px !important;
    height: 54px !important;
    margin-bottom: 24px;
}

.btn-action {
    margin-top: 24px;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;
    background: #F29423;

    &:hover, &:focus {
        color: #ffffff;
        background: #F29423;
    }
}
