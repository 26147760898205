@import '../../style/typography.scss';

.info-inputbox {
    display: flex;
    flex-direction: column;
}

.info-input,
.info-select a,
.info-date-picker a {
    @extend .font-size-100-regular;

    --_border: 1px solid #bcbcbc;

    padding: 8px 12px !important;

    height: 36px !important;
    border-radius: 4px !important;
    border-top: var(--_border) !important;
    border-bottom: var(--_border) !important;
    border-right: var(--_border) !important;
    border-left: var(--_border) !important;
    &:hover,
    &:focus {
        border-color: #378ef0 !important;
    }
}

.info-input {
    @extend .font-size-100-regular;
    padding: 8px 12px;
    background-color: var(--white, #ffffff);
    outline: none;
    line-height: 20px;
}

.info-label {
    @extend .font-size-100-regular;
    display: block;
    margin-block-end: 0.5rem;
}

.info-date-picker.disabled {
    pointer-events: none;
}

.info-error-message {
    --text-color: #ec5b62;
    @extend .font-size-75-regular;
}

.info-numeric-input {
    --_border: 1px solid #bcbcbc;
    border-radius: 4px !important;
    border-top: var(--_border) !important;
    border-bottom: var(--_border) !important;
    border-right: var(--_border) !important;
    border-left: var(--_border) !important;
    height: 36px !important;

    &:hover,
    &:focus {
        border-color: #378ef0 !important;
    }

    input {
        all: unset;
        font: inherit;
        height: 100%;
        padding-inline: 12px;
        width: 100%;
        display: inline-block;
        box-sizing: border-box;
    }
}

.info-number-input {
    border-radius: 4px !important;
    height: 36px !important;
}

.info-input-addon {
    background: var(--gray-200, #f4f4f4);
}

.info-checkbox {
    label {
        @extend .font-size-100-regular;
    }

    :global(.rs-checkbox-wrapper) {
        &::after {
            inset: 0px;
        }
    }

    :global(.rs-checkbox-wrapper) {
        top: 2px;
        left: 0px;
    }

    :global(.rs-checkbox-checker) {
        padding-top: 0px;
        padding-bottom: 0px;
        min-height: 0px;
        padding-left: 24px;
        padding-right: 8px;
    }
}

.radio-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
