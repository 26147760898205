:root {
    --header-height: 56px;
    --base-transition: ease 250ms;

    @media only screen and (max-width: 480px) {
        --header-height: 105px;
    }
}

html {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Open Sans', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    min-height: 100vh;
    height: 100vh;
}

input {
    border-top: none !important;
    border-radius: 0 !important;
    border-right: none !important;
    border-left: none !important;
}

.input-bordered {
    input {
        border: 1px solid #e5e5ea !important;
        border-top: 1px solid #e5e5ea !important;
        border-right: 1px solid #e5e5ea !important;
        border-left: 1px solid #e5e5ea !important;
        border-radius: 6px !important;
    }
}

#date-filter {
    &.rs-picker-toggle {
        border-top: none !important;
        border-radius: 0 !important;
        border-right: none !important;
        border-left: none !important;
        z-index: 0 !important;
    }
}

.rs-flex-box-grid-item {
    margin-bottom: 25px;
}

.rs-sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 1px;
    height: 99.8vh;
}

.rs-navbar {
    background-color: rgb(30, 136, 229) !important;
    position: fixed;
    width: 100%;
    z-index: 9;
}

.navbar-brand {
    background-color: 'rgb(30,136,229)' !important;
    vertical-align: middle;
    display: table-cell;
    font-size: 20px;
}

.rs-navbar-header {
    display: table;
    margin-left: 20px;
}

.show-fake-browser {
    -webkit-box-shadow: 0 0.1em 0.5em 0 rgba(0, 0, 0, 0.28);
    box-shadow: 0 0.1em 0.5em 0 rgba(0, 0, 0, 0.28);
    border-radius: 4px;
    background: #f7f7fa;
    height: 100%;

    .first-container {
        height: 100%;
        min-height: 100vh;
    }

    &.sidebar-page {
        .rs-sidebar {
            -webkit-box-shadow:
                0 4px 4px rgba(0, 0, 0, 0.12),
                0 0 10px rgba(0, 0, 0, 0.06);
            box-shadow:
                0 4px 4px rgba(0, 0, 0, 0.12),
                0 0 10px rgba(0, 0, 0, 0.06);

            .rs-sidenav {
                -webkit-box-flex: 1;
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
                width: 100%;

                .rs-sidenav-body {
                    .rs-nav {
                        .badge-item {
                            .rs-nav-item-content {
                                .custom-icon {
                                    top: 7px;
                                }

                                div#badge {
                                    position: absolute;
                                    font-weight: 700;
                                    font-size: 8px;
                                    text-transform: uppercase;
                                    background: #378ef0;
                                    border-radius: 10px;
                                    color: #ffffff;
                                    padding: 1px 6px;
                                    top: 30px;
                                    left: 11px;
                                    z-index: 2;
                                }
                            }
                        }
                    }
                }
            }
        }

        .rs-sidenav {
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            width: 100%;
        }

        .rs-sidenav-collapse-in {
            overflow-y: auto;
        }

        .rs-header {
            padding: 0 20px;
        }

        .rs-content {
            padding: 20px;
            margin: 20px;
            background: #fff;
        }

        .nav-toggle {
            border-top: 1px solid #e5e5ea;
        }
    }

    &.navbar-page {
        .rs-content {
            padding: 20px;
            margin: 20px;
            height: 500px;
            background: #fff;
        }

        .rs-footer {
            padding: 20px;
        }
    }

    &.login-page {
        .rs-content {
            padding: 20px;
            margin: 20px;
            height: 500px;
        }

        .rs-footer {
            padding: 20px;
        }

        .rs-panel {
            background: #fff;
            margin-top: 70px;
        }
    }
}

.rs-sidebar {
    &.rs-sidebar-collapse {
        z-index: 1;
    }
}

.rs-sidenav-inverse {
    background-color: #3498ff;
}

// Needs to be here, it is outside of ondemand page
.request-summary__title-wrapper__tooltip {
    width: 418px;
    line-height: 16px;

    .rs-tooltip-inner {
        text-align: start;
        max-width: fit-content;

        ul {
            padding-inline-start: 16px;
        }
    }
}

.width-100-percent {
    width: 100%;
}

.height-100-percent {
    height: 100%;
}

.mg-bottom-0 {
    margin-bottom: 0;
}

.mg-bottom-15 {
    margin-bottom: 15px;
}

.mg-right-0 {
    margin-right: 0;
}

.mg-right-10 {
    margin-right: 10px;
}

.mg-right-15 {
    margin-right: 15px;
}

.mg-right-28 {
    margin-right: 28px;
}

.mg-left-7 {
    margin-left: 7px;
}

.mg-left-10 {
    margin-left: 10px;
}

.pd-top-10 {
    padding-top: 10px;
}

.pd-0 {
    padding: 0 !important;
}

.display-flex {
    display: flex;
}

.list-style-none {
    list-style-type: none;
}

.inline-flex {
    display: inline-flex !important;
}

.text-center {
    text-align: center;
}

.font-weight-600 {
    font-weight: 600;
}

.font-size-25 {
    font-size: 25px;
}

.cursor-pointer {
    cursor: pointer;
    color: #1675e0;
    text-decoration: none;
}

.main-container {
    margin-top: 55px;
    z-index: 0;
    max-width: 100vw;

    .content-title {
        font-size: 20px;
        line-height: normal;
    }

    .content-subtitle {
        margin-top: 12px;
    }
}

.content-title {
    font-size: 20px;
    line-height: normal;
}

.content-subtitle {
    margin-top: 12px;
}

@media screen and (max-width: 320px) {
    .ultima-atualizacao {
        max-width: 250px !important;
    }
}

@media screen and (max-width: 480px) {
    .ultima-atualizacao {
        max-width: 250px !important;
    }

    .main-container {
        margin-top: 110px;
    }
}

@media screen and (max-width: 500px) {
    .ultima-atualizacao {
        max-width: 250px !important;
    }
}

@media (min-width: 760px) {
    .rs-grid-container {
        width: 760px;
    }
}

@media (min-width: 960px) {
    .rs-grid-container {
        width: 960px;
    }
}

@media (min-width: 1000px) {
    .rs-grid-container {
        width: 1000px;
    }
}

@media (min-width: 1200px) {
    .rs-grid-container {
        width: 1200px;
    }
}

@media (min-width: 1280px) {
    .rs-grid-container {
        width: 1280px - 15px;
    }
}

@media (min-width: 1366px) {
    .rs-grid-container {
        width: 1366px - 15px;
    }
}

@media (min-width: 1400px) {
    .rs-grid-container {
        width: 1400px;
    }
}

@media (min-width: 1440px) {
    .rs-grid-container {
        width: 1440px - 15px;
    }
}

@media (min-width: 1440px) {
    .rs-grid-container {
        width: 1440px - 15px;
    }
}

@media (min-width: 1536px) {
    .rs-grid-container {
        width: 1536px - 15px;
    }
}

@media (min-width: 1600px) {
    .rs-grid-container {
        width: 1600px;
    }
}

@media (min-width: 1800px) {
    .rs-grid-container {
        width: 1800px;
    }
}

@media (min-width: 1920px) {
    .rs-grid-container {
        width: 1920px - 15px;
    }
}

@media (min-width: 2000px) {
    .rs-grid-container {
        width: 2000px;
    }
}

@media (min-width: 2200px) {
    .rs-grid-container {
        width: 2200px;
    }
}

//rebrand
.rs-btn-primary {
    background-color: #378ef0;
    border-color: #378ef0;
}

.rs-btn-ghost {
    border-color: #378ef0 !important;
    color: #378ef0;
}

.rs-input {
    &:focus,
    &:hover {
        border-color: #378ef0 !important;
    }
}

.card {
    background: var(--white, #fff);
    border-radius: 6px;
    padding: 16px;
}

.rs-picker-default .rs-picker-toggle,
.rs-picker-default .rs-picker-toggle-custom {
    z-index: 0;
}

.color-disruptive {
    --text-color: var(--red-400);
    color: var(--text-color);
}

/* FILTERS STYLES */
.info-filter {
    --clr-bg: var(--gray-100);
    --clr-text: var(--gray-800);
    --clr-icon: var(--gray-700);
    --clr-icon-container: var(--gray-300);
    --clr-selected-items-pill: #378ef0;
    --border-radius: 99999px;
    display: flex;
    align-items: center;
    background: var(--clr-bg);
    border-radius: var(--border-radius);
    transition: var(--base-transition);

    .counter {
        border-radius: 14px;
        background: var(--clr-selected-items-pill);
        padding: 2px 8px;
        color: var(--white, #fff);
        font-family: 'Open Sans';
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px; /* 120% */
        text-transform: uppercase;
    }

    .filter-start-icon,
    .filter-end-icon {
        background-color: var(--clr-icon-container);
        transition: var(--base-transition);
        color: var(--clr-icon);
    }

    &:has(.rs-picker-toggle-clean) {
        .rs-picker-toggle-caret {
            right: 28px !important;
        }

        .rs-picker-toggle-clean {
            right: 11px !important;
        }
    }

    .rs-picker-toggle,
    .rs-picker-disabled {
        border: none !important;
        background: transparent !important;
        transition: var(--base-transition);
    }

    .rs-picker-toggle-placeholder,
    .rs-picker-toggle-clean,
    .rs-picker-toggle-caret,
    .rs-picker-toggle-value {
        color: var(--clr-text) !important;
        transition: var(--base-transition);
    }

    .rs-picker-toggle-clean {
        top: 7px !important;
    }

    &:hover {
        --clr-text: var(--gray-900);
    }

    &:has(.rs-picker-toggle.active) {
        --border-radius: 18px 18px 0px 0px;
        --clr-bg: #eaeaea;
    }

    &:has(.rs-picker-has-value) {
        --clr-bg: #f5f9fe;
        --clr-icon-container: #ebf4fe;
        --clr-text: var(--blue-500);
        --clr-icon: var(--blue-500);

        &:hover {
            --clr-text: var(--blue-700);
        }

        &:has(.rs-picker-toggle.active) {
            --clr-bg: #e1eefd;
        }
    }

    &-error {
        --clr-bg: #fef7f7;
        --clr-text: #d7373f;
        --clr-icon: #d7373f;
        --clr-icon-container: #fbdee0;

        &:not(:has(.rs-picker-disabled)):hover {
            --clr-text: #d7373f;
            --clr-icon: #d7373f;
        }

        &:has(.rs-picker-toggle.active) {
            --clr-bg: #fef7f7;
        }

        &:has(.rs-picker-has-value) {
            --clr-bg: #fef7f7;
            --clr-text: #d7373f;
            --clr-icon-container: #fbdee0;
            --clr-selected-items-pill: #ec5b62;

            &:has(.rs-picker-toggle.active) {
                --clr-bg: #fef7f7;
            }

            &:hover {
                --clr-text: #d7373f;
            }
        }
    }

    &:has(.rs-picker-disabled) {
        --clr-bg: var(--gray-100);
        --clr-text: var(--gray-500);
        --clr-icon: var(--gray-400);
        --clr-icon-container: var(--gray-300);

        cursor: not-allowed;

        & > * {
            pointer-events: none;
        }

        .rs-picker-toggle {
            background: none !important;
        }
    }
}

.filter-button {
    border-radius: 9999px;
    height: 36px;
    padding-inline: 12px;
    display: grid;
    place-content: center;
}
